import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from 'react-html-parser';
import {withTranslation} from "react-i18next";

import SectionTitle from "../SectionTitle";

import nextIcon from '../../images/icon/next.png';
import prevIcon from '../../images/icon/prev.png';

import './style.scss';

const settings = {
    dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, 
        responsive: [
          {
          breakpoint: 992,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              arrows: true
          }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true
          }
          },
          {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true
          }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
          ]
};

class Advisor extends React.Component {

    nextHandler = () => {
        this.slider.slickNext();
    };
    prevHandler = () => {
        this.slider.slickPrev();
    };

    render() {

        return (
            <Grid className='advisor-services'>
            <Grid  className="container"> 
            <Grid className="row">
            <Grid className="col-lg-4 col-md-4 col-sm-4 com-xs-12 hidden-xs hidden-sm"></Grid>
            <Grid className="col-lg-8 col-md-8  col-sm-12 com-xs-12">
                  <Grid class="advisor-class-box">
            <h1 className="advisor-class"> Our Advisors</h1>
            </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12}>
            
          
            <Grid className="ourExpert">
            {this.props.advisorList !== undefined ?
            <Fragment>
           
             {this.props.advisorList.map(item => {

                return (
                    <Grid key={item.id} className="expertTeam management-team">
                         <Grid className="" data-wow-delay="0.2s">
                                            <Grid className="row">
                                                <Grid className="col-md-4 col-sm-4 com-xs-12">
                                                    <Grid className="management-team-left">
                                                        <Grid className="management-team-img"><img src={item.image} alt=""/></Grid>
                                                       </Grid>
                                                </Grid>
                                                  <Grid className="col-md-8 col-sm-8 com-xs-12">
                                                    <Grid className="management-team-right team-wrapper">
                                                        <h4>{ReactHtmlParser(item.title ? item.title : '')}</h4>
                                                        <Grid><p>{ReactHtmlParser(item.description ? item.description : '')}</p></Grid> 
                                                    </Grid>
                                                </Grid>
                                            </Grid>                  
                        </Grid>
                     </Grid>
                    
                )

             })} 
           
           </Fragment>
            : '' }

            </Grid>
            </Grid>
            </Grid>
            </Grid>
           )
         }
       }


const mapStateToProps = state => {
    return {
        advisorList: state.home.advisor_list
    }
};

export default connect(mapStateToProps)(withTranslation('common')(Advisor));