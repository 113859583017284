import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import ContactIntro from "../../components/ContactIntro";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import Event from "../../components/Event";
import EventsTop from "../../components/EventsTop";
import Testimonial from "../../components/Testimonial";
import { withTranslation } from 'react-i18next';

import './style.scss';


import {loadAboutAction} from "../../store/actions/action";


const KnowledgePage = (props) => {

    useEffect(() => {
        props.loadAboutAction();
    }, []);
    

    const {about_banner_title} = props.about;

    return (
        <Fragment>
            <EventsTop/>
            <Event/>
            {props.about.frontend_setting == '1' ? <Testimonial/> : '' }
            <Footer/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        about: state.about
    }
};

export default connect(mapStateToProps, {loadAboutAction})(KnowledgePage);
