import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";

import {withTranslation} from "react-i18next";

import SectionTitle from "../SectionTitle";

import nextIcon from '../../images/icon/next.png';
import prevIcon from '../../images/icon/prev.png';

import './style.scss';

const settings = {
    dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, 
        responsive: [
          {
          breakpoint: 992,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              arrows: true
          }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true
          }
          },
          {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true
          }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
          ]
};

class TeamHome extends React.Component {

    nextHandler = () => {
        this.slider.slickNext();
    };
    prevHandler = () => {
        this.slider.slickPrev();
    };

    render() {

        return (
                    <Grid item xs={12}>
                        <Grid className="ourExpert services-slider">
                            {this.props.teamList !== undefined ?
                                <Fragment>
                                    <Slider {...settings} ref={slider => (this.slider = slider)} {...settings}
                                            slidesToShow={this.props.teamList.length !== undefined ? this.props.teamList.length >= 3 ? 3 : this.props.teamList.length : 3}>
                                        {this.props.teamList.map(item => {
                                            return (
                                                 <Grid key={item.id} className="expertTeam slide-box">
                                                        <Grid className="top-img spin circle"><img src={item.image} alt=""/></Grid>
                                                        <Grid className="content">
                                                            <h4>{item.name}</h4>
                                                            <p>{item.designation}</p>
                                                        <a href="/services" className="read-more">Read More</a>
                                                        </Grid>
                                                    </Grid>
                                            )
                                        })}
                                    </Slider>
                                    {/* <ul className="sliderNav">
                                        <li onClick={this.prevHandler}><img src={prevIcon} alt="prev"/></li>
                                        <li onClick={this.nextHandler}><img src={nextIcon} alt="next"/></li>
                                    </ul> */}
                                </Fragment> : ''}
                        </Grid>
                    </Grid>
               
        )
    }
}

const mapStateToProps = state => {
    return {
        teamList: state.home.team_list
    }
};

export default connect(mapStateToProps)(withTranslation('common')(TeamHome));