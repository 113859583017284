import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import Hidden from "@material-ui/core/Hidden";
import {loadServiceAction} from "../../store/actions/action";

// images
import Quotation from '../../images/quotation-marks.png';
import thumb from '../../images/tstthumb.jpg';
import next from '../../images/icon/next.png';
import prev from '../../images/icon/prev.png';

import './style.scss';


function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <img
            src={next}
            className={className}
            style={{...style, display: "inline-block"}}
            onClick={onClick}
            alt="next"
        />
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <img
            src={prev}
            className={className}
            style={{...style, display: "inline-block"}}
            onClick={onClick}
            alt="prev"
        />
    );
}

const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
        {
            breakpoint: 374,
            settings: {
                slidesToShow: 1,
            }
        }
    ],
};

const ServiceMobile = (props) => {
    useEffect(() => {
        props.loadServiceAction();
    }, []);

    return (
       
        <Grid className="testimonialCarousel1">
            <Grid container className="container">
                <Hidden smDown><Grid item md={1}></Grid></Hidden>
                <Grid item xs={12} md={10}>
                    {props.service.service_list !== undefined ? <Slider {...settings}>
                        {props.service.service_list.map(item => {
                            return (
                                    <Grid key={item.id} className="expertTeam services-box">
                                        <Grid className="top-img"><img src={item.icon_image} alt=""/></Grid>
                                        <Grid className="content">
                                            <h4>{item.title}</h4>
                                            <p>{item.description.substr(' ',56)}</p>
                                        <a href={'/services#' + item.id} className="read-more">Read More</a>
                                        </Grid>
                                    </Grid>

                            )
                        })}
                    </Slider> : ''}
                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => {
    return {
        service: state.service
    }
};

export default connect(mapStateToProps, {loadServiceAction})(ServiceMobile);
