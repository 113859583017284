import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid} from "@material-ui/core";
import ReactHtmlParser from 'react-html-parser';
import Featured from "../../components/Featured";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import {loadServiceAction} from "../../store/actions/action";
import './style.scss';

const Services = (props) => {

    useEffect(() => {
        props.loadServiceAction();
    }, []);

    return (
        <Fragment>
              <Grid className="servicesIntroWrapper">
                <Grid className="inner-page-banner">
                    <Grid className="banner-img"><img src={props.service.service_banner_image ? props.service.service_banner_image : ''} alt=""/></Grid>
                    <Grid className="content">
                        <Grid className="inner-content"> 
                            {ReactHtmlParser(props.service.service_banner_title ? props.service.service_banner_title : '')}
                        </Grid>
                        <ul className="breadcrumb wow fadeInLeft" data-wow-delay="0.4s">
                            <li><a href="/home">Home</a></li>
                            <li className="active">Services</li>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
            {/* <BreadCrumb
                // pagename="Our Services"
                title={props.service.service_banner_image ? props.service.service_banner_image : 'props.service.service_banner_image'}
            /> */}
            <Featured features={props.service.service_list} className="allFeaturs"/>
            <Footer/>
        </Fragment>
    )
};
const mapStateToProps = state => {
    return {
        service: state.service
    }
};
export default connect(mapStateToProps, {loadServiceAction})(Services);