import React, {Fragment, useEffect, Component, useState} from "react";
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import Hidden from "@material-ui/core/Hidden";

import ReactHtmlParser from 'react-html-parser';

import './style.scss';


const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
          autoPlay: true,
          responsive: [
              {
              breakpoint: 678,
              settings: {
                  arrows: false
              }
              },
              ]
  };

  const HomeSlider = (props) => {
    const {home_banner_image1, home_banner_image2, home_banner_title1, home_banner_des1, home_banner_title2, home_banner_des2} = props.homeSliderInfo;
    return (
              <Grid className="slider-wrapper">
                      <Hidden smDown><Grid item md={1}></Grid></Hidden>
                      <Grid className="carousel-inner" item xs={12} md={12}>

                        <Slider {...settings}>

                            <Grid className="item">
                              <img src={home_banner_image1 ? home_banner_image1 : ''} alt="" />
                              <Grid className="carousel-caption">
                              <h1>{ReactHtmlParser(home_banner_title1 ? home_banner_title1 : '')}</h1>
                              {ReactHtmlParser(home_banner_des1 ? home_banner_des1 : '')}
                              </Grid>
                            </Grid>

                            {/* <Grid className="item">
                              <img src={home_banner_image2 ? home_banner_image2 : ''} alt="" />
                              <Grid className="carousel-caption">
                              <h1>{ReactHtmlParser(home_banner_title2 ? home_banner_title2 : '')}</h1>
                              {ReactHtmlParser(home_banner_des2 ? home_banner_des2 : '')}
                             
                              </Grid>
                            </Grid> */}

                          </Slider>
                      </Grid>
              </Grid>
    )
  };

const mapStateToProps = state => {
  return {
    homeSliderInfo: state.home
  }
};

export default connect(mapStateToProps)(HomeSlider);
