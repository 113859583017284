import React, {Fragment, useState, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from 'react-html-parser';
import Button from "@material-ui/core/Button";
import Lightbox from 'react-image-lightbox';
import {toast} from "react-toastify";
import 'react-image-lightbox/style.css';
import Modal from 'react-bootstrap/Modal'
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery'; 
import Files from 'react-files'
import './style.scss';

import {postRequest} from "../../utils/request";

const Job = ({className = '', jobs}) => {
   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [mobile, setMobile] = React.useState("");

    const [job, setJob] = React.useState("");
    const [location, setLocation] = React.useState("");

    const[modelId, setModelId] = useState();
    function onOpenHandler(id) {
        setModelId(id);
        setShow(true);
    }

    const [gcaptcha_code, setgcaptcha_code] = React.useState("");
   // console.log("gcaptcha_code :" + gcaptcha_code);
      console.log("gcaptcha_code :" + jobs.google_capcha_site_key);
   
    
    const [files, setFiles] = React.useState({});

    const handleLocationSubmit = (event) => {
        event.preventDefault();
        const data = {
            job: event.target.job.value,
            location: event.target.location.value,
        };
      
        if (event.target.job.value !== '' || event.target.location.value) {
             postRequest('job-search', data)
                 .then(res => {
                    //console.log(res.data);
                      localStorage.setItem('react_response',JSON.stringify(res.data));
                      if (res.data.success === true) {
                          console.log("data sent successfully");
                          toast.success(res.data.message);
                             setJob("");
                             setLocation("");
                      } else {
                          console.log("data not sent successfully");
                          toast.error(res.data.message);
                      }
                 })
                 .catch(error => {
                     console.log("data error");
                 });
         }
      }

    var glob_data =  JSON.parse(localStorage.getItem('react_response'));
    var data_glob = glob_data ? glob_data : jobs ;
    //var location_array = data_glob.location_arr ;
     // console.log(location_array);
    //var arr = Object.values(location_array);
    //var array_location = Object.values(location_array);
    
    //console.log(arr);
    
  
    const handleSubmit = (event) => {
        event.preventDefault();

       
         const formData = new FormData();
		 formData.append('file',files, files.name);
         formData.append('filename', files.name);
         formData.append('jobId',modelId);
         formData.append('name',event.target.name.value);
         formData.append('email',event.target.email.value);
         formData.append('mobile',event.target.mobile.value);

        

        // const data = {
        //     jobId: modelId,
        //     name: event.target.name.value,
        //     email: event.target.email.value,
        //     mobile: event.target.mobile.value,
        //     file:   formData,
        // }; 
        //console.log(files.name);
        //console.log(formData);
      
        if (event.target.email.value !== '' && event.target.mobile.value) {
            if (gcaptcha_code !='') {
            // if (!error) {
             postRequest('job-apply', formData)
                 .then(res => {
                     console.log(res.data);
                   
                     if (res.data.success === true) {
                         console.log("data sent successfully");
                         toast.success(res.data.message);
                            setName("");
                            setEmail("");
                            setMobile("");
                     } else {
                         console.log("data not sent successfully");
                         toast.error(res.data.message);
                     }
                 })
                 .catch(error => {
                     console.log("data error");
                     toast.error('Server error!');
                 });
         }  else {
            toast.error('Please Validate the Captcha!');
       }
    }

    }
    

     

    return (

        <Grid>

           <Grid className="who-we-are-wrapper career-wrapper"> 
                <Grid container alignItems="center" className="container">
                        <Grid item xs={12} sm={5} md={5} className="align-self-start" >
                            <Grid className="who-we-are-left">
                               <Grid className="who-we-are-img wow fadeInUp"><img src={jobs.why_join_image ? jobs.why_join_image : ''} alt=""/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Grid className="who-we-are-right-content">
                                <Grid className="top-content wow fadeInUp">
                                    <h1>{ReactHtmlParser(jobs.why_join_title ? jobs.why_join_title : '')}</h1>
                                        {ReactHtmlParser(jobs.why_join_des ? jobs.why_join_des : '')}
                                </Grid>  

                            </Grid>
                        </Grid>
                </Grid>
            </Grid>


            <Grid className={`careerlistWrapper ${className}`}>

                <Grid className="career-list-wrapper">
                    <Grid className="container">    

                    <Grid className="career-search wow fadeInUp" >


                                                        <form className="form-inline" onSubmit={handleLocationSubmit} >
                                                        <Grid className="input-group">
                                                            <Grid className="input-group-prepend">
                                                                <button className="btn btn-default btn-lg" type="submit">
                                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                                </button>
                                                            </Grid>
                                                             <Grid className="jobTitle">
                                                                <input
                                                                    className="form-control input-lg"
                                                                    name="job"
                                                                    type="text"
                                                                    placeholder="Search job by job title"
                                                                    value={job}
                                                                    onChange={e => setJob(e.target.value)}
                                                                    />
                                                               </Grid>
                                                        </Grid>       
                                                               <Grid className="form-group">
                                                               <select  className="form-control input-lg" name="location" value={location} onChange={e => setLocation(e.target.value)} >
        
                                                                <option value="">Please Select Location </option>
                                                                     {data_glob.location_arr !== undefined ? data_glob.location_arr.map(item => {
                                                                      return (
                                                                     <option value={ReactHtmlParser(item ? item : '')}>{ReactHtmlParser(item ? item : '')}</option>
                                                                    )
                                                                 }) : ''} 
                                                                 
                                                                {/* <option value="Delhi">Delhi</option>
                                                                 <option value="Mumbai">Mumbai</option>
                                                                 <option value="Gurgoan">Gurgoan</option>
                                                                 <option value="Noida">Noida</option>
                                                                 <option value="Banglore">Banglore</option> */}
                                                                </select>
                                                                </Grid>
                                                        </form>

                    </Grid>

                        <Grid className="career-list">
                            <h3 className="wow fadeInUp" data-wow-delay="0.2s">{ReactHtmlParser(data_glob.total_jobs ? data_glob.total_jobs : '')} positions open</h3>
                            <Grid className="row">
                            
                                {data_glob.job_list !== undefined ? data_glob.job_list.map(item => {
                                  
                                return (
                                          
                                            <Grid className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <Grid className="career-box wow fadeInRight" data-wow-delay="0.2s" key={item.id}>
                                                    <Grid className={`${data_glob.job_list.length - 1 === item.id ? 'active' : ''}`}>
                                                        <Grid className="career-head">
                                                            <Grid className="head-left">
                                                                <h4>{ReactHtmlParser(item.title ? item.title : '')}</h4>
                                                            </Grid>
                                                            <Grid className="head-right">Job Posted : <i className="fa fa-clock-o" aria-hidden="true"></i> {ReactHtmlParser(item.created_at ? item.created_at : 'created_at')}</Grid>
                                                        </Grid>
                                                        
                                                        <Grid className="career-body">
                                                            <Grid className="row">
                                                                <Grid className="col-12 col-sm-6 col-md-6">
                                                                    <ul>
                                                                       <li className="employee-type">Employment Type: {ReactHtmlParser(item.emp_type ? item.emp_type : ' ')}</li>
                                                                        <li className="key-skills">Key Skills: {ReactHtmlParser(item.key_skills ? item.key_skills : ' ')}</li>
                                                                    </ul>
                                                                </Grid>
                                                                <Grid className="col-12 col-sm-6 col-md-6">
                                                                    <ul>
                                                                        <li className="age-between">Work Exp: {ReactHtmlParser(item.work_exp_min ? item.work_exp_min : ' ')} - {ReactHtmlParser(item.work_exp_max ? item.work_exp_max : ' ')} years </li>
                                                                        <li className="location">Location: {ReactHtmlParser(item.location ? item.location : ' ')}</li>
                                                                    </ul>
                                                                </Grid>
                                                                <Grid className="col-12 col-sm-4 col-md-4">
                                                                    <ul>
                                                                       
                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid className="career-description">
                                                                <h5>Job Description:</h5> {ReactHtmlParser(item.description ? item.description : ' ')}
                                                            </Grid>
                                                            </Grid>
                                                        <Grid className="career-foot">
                                                            <Grid>
                                                            <Button className="apply-now-btn" variant="primary" data_id={item.id} onClick={() => onOpenHandler(item.id)}>Apply Now</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-90w modal-dialog-centered career-popup" aria-labelledby="example-custom-modal-styling-title" >
                                                        <Modal.Header closeButton>
                                                        <Modal.Title id="example-custom-modal-styling-title">
                                                        Applicants Details
                                                        </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                       
                                                             <form onSubmit={handleSubmit} encType="multipart/form-data" >
                                                             <Grid className="form-group">
                                                                 <label>
                                                                   Please Enter Your Name:
                                                                 </label>
                                                                    <input
                                                                    className="form-control form-control-lg"
                                                                    name="name"
                                                                    type="name"
                                                                    value={name}
                                                                    onChange={e => setName(e.target.value.replace(/[^\w\s]/gi, ""))}
                                                                    required />
                                                                
                                                             </Grid>
                                                             <Grid className="form-group">
                                                                <label>
                                                                Please Enter Your Email-Id:
                                                                   </label>  
                                                                   <input
                                                                     className="form-control form-control-lg"
                                                                    name="email"
                                                                    type="email"
                                                                    value={email}
                                                                    onChange={e => setEmail(e.target.value)}
                                                                    required />
                                                               
                                                                </Grid>
                                                                <Grid className="form-group">
                                                                 <label>
                                                                 Please Enter Your Mobile Number:
                                                                   </label>  
                                                                   <input
                                                                     className="form-control form-control-lg"
                                                                    name="mobile"
                                                                    type="text"
                                                                    min="10"
                                                                    maxLength="10"
                                                                    value={mobile}
                                                                    onChange={e => setMobile(e.target.value.replace(/[^\w\s]/gi, ""))}
                                                                    required />
                                                               
                                                                </Grid>


                                                                <Grid className="form-group">
                                                                 <label>
                                                                 Please Upload Your CV:
                                                                 </label>
                                                                   <input name="files"
                                                                    id = "files"
                                                                    className="form-control form-control-lg"
                                                                    type="file" 
                                                                    onChange={e => setFiles(e.target.files[0])}
                                                                    required
                                                                  />  
                                                                <p>Note: File should be less then or equal 2 MB.</p>
                                                                </Grid>

                                                                <Grid className="form-group">
                                                                       <ReCAPTCHA
                                                                        sitekey={jobs.google_capcha_site_key}
                                                                        onChange={e => setgcaptcha_code(jobs.google_capcha_site_key)}
                                                                        value={gcaptcha_code}
                                                                        />
                                                                    {/* <ReCAPTCHA
                                                                        sitekey={'6Le3Z1EcAAAAANA6kh3IcfKYYkMSD02gsIpfHVj_'}
                                                                        onChange={e => setgcaptcha_code('6Le3Z1EcAAAAANA6kh3IcfKYYkMSD02gsIpfHVj_')}
                                                                        value={gcaptcha_code}
                                                                        /> */}
                                                                </Grid> 
                                                              
                                                                <button className="btn btn-default">Submit</button>
                                                                </form>
                                                           
                                                        </Modal.Body>
                                                    </Modal>
                                                </Grid>
                                            </Grid>

                                        
                                    )
                                }) : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
    )
};

window.localStorage.clear();
export default Job;