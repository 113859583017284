import {LOAD_EVENT_SUCCESSFUL} from "../actions/type";

const init = {};

const eventReducer = (state = init, action) => {
    switch (action.type) {
        case LOAD_EVENT_SUCCESSFUL: {
            return action.data;
        }
        default:
            return state;
    }
};
export default eventReducer;