import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import AboutIntro from "../../components/AboutIntro";
import AboutDigitalStudio from "../../components/AboutDigitalStudio";
import DigitalStudio from "../../components/DigitalStudio";
import Footer from "../../components/Footer";
import ContactUs from "../../components/ContactUs";
import BreadCrumb from "../../components/BreadCrumb";
import {loadAboutAction} from "../../store/actions/action";


const AboutUs = (props) => {

    useEffect(() => {
        props.loadAboutAction();
    }, []);
    
    const {about_banner_title} = props.about;
    //console.log(props.about);
   
    return (
        <Fragment>
            <AboutIntro about={props.about}/>
            {/* <ContactUs/> */}
            <Footer/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        about: state.about
    }
};

export default connect(mapStateToProps, {loadAboutAction})(AboutUs);