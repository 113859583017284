import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import ContactIntro from "../../components/ContactIntro";
import NewsLetter from "../../components/NewsLetter";
import Footer from "../../components/Footer";
import ContactInfoList from "../../components/ContactInfoList";
import ContactUs from "../../components/ContactUs";
import Address from "../../components/Address";
import BreadCrumb from "../../components/BreadCrumb";

import { withTranslation } from 'react-i18next';

import './style.scss';


import {loadAboutAction} from "../../store/actions/action";


const ContactUsPage = (props) => {

    useEffect(() => {
        props.loadAboutAction();
    }, []);
   
    const {about_banner_title} = props.about;

    return (
        <Fragment>
            <ContactIntro about={props.about}/>
            <Address about={props.about} /> 
            <ContactUs/>
            <Footer/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        about: state.about
    }
};

export default connect(mapStateToProps, {loadAboutAction})(ContactUsPage);
