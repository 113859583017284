import React from 'react';
import {Grid} from "@material-ui/core";
import ReactHtmlParser from 'react-html-parser';

// images
import bgShape2 from '../../images/bg-shape2.png';

import './style.scss';

const Featured = ({className = '', features}) => {
    return (
        <Grid className={`featuredWrapper ${className}`}>
            <Grid container spacing={4} className="container xlg-pt-50" >
                   {features !== undefined ? features.map(item => {
                                       
                    // if(item.id % 2 == 0 ){
                        if(item.sorting_order % 2 == 0 ){
                      
                        return (
                    
                                <Grid className="service-list wow fadeInRight" id={item.id}>
                                        <Grid className="row">
                                            <Grid className="col-md-6 order-md-2 col-sm-12 order-sm-1 col-12 order-1">
                                                <Grid className="left-img pharamacovigilance">
                                                    {/* <Grid className="icon wow bounceInDown" data-wow-delay="0.4s"><img src={item.icon_image} alt=""/></Grid> */}
                                                    <img src={item.image} alt=""/></Grid>
                                            </Grid>
                                            <Grid className="col-md-6 order-md-1 col-sm-12 order-sm-2 col-12 order-2">
                                                <Grid className="content">
                                                    <h1>{ReactHtmlParser(item.title ? item.title : '')}</h1>
                                                    <p>{ReactHtmlParser(item.description ? item.description : '')}</p>
                                                    <p>{ReactHtmlParser(item.read_more_description ? item.read_more_description : '')}</p>
                                                    <Grid className="orange-btn">{ReactHtmlParser(item.service_button ? item.service_button : '')}</Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                        ) 
                     } else {
                       
                        return (   
                            <Grid className="service-list wow fadeInRight" id={item.id}>
                                    <Grid className="row">
                                        <Grid className="col-md-6 col-sm-12 col-12">
                                            <Grid className="left-img pharamacovigilance">
                                                {/* <Grid className="icon wow bounceInDown" data-wow-delay="0.4s"><img src={item.icon_image} alt=""/></Grid> */}
                                                <img src={item.image} alt=""/></Grid>
                                        </Grid>
                                        <Grid className="col-md-6  col-sm-12 col-12">
                                            <Grid className="content">
                                                <h1>{ReactHtmlParser(item.title ? item.title : '')}</h1>
                                                <p>{ReactHtmlParser(item.description ? item.description : '')}</p>
                                                <p>{ReactHtmlParser(item.read_more_description ? item.read_more_description : '')}</p>
                                                <Grid className="orange-btn">{ReactHtmlParser(item.service_button ? item.service_button : '')}</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                               )
                            }

                                        // <Grid key={item.id} item xs={12} md={4} sm={6}>
                                        //     <Grid className={`featuredItem ${features.length - 1 === item.id ? 'active' : ''}`}>
                                        //         <Grid className="icon">
                                        //             <img src={item.image} alt=""/>
                                        //         </Grid>
                                        //         <Grid className="content">
                                        //             <h2>{item.title}</h2>
                                        //             <p>{item.description}</p>
                                        //         </Grid>
                                        //     </Grid>
                                        // </Grid>
                   
                        
                }) : ''}
            </Grid>
            {/* <img className="bgShape2" src={bgShape2} alt="bgShape2"/> */}
        </Grid>
    )
};

export default Featured;