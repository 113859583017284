import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import {loadVideoAction} from "../../store/actions/action";

import ReactPlayer from "react-player";

import {withTranslation} from "react-i18next";

import SectionTitle from "../SectionTitle";

import nextIcon from '../../images/icon/next.png';
import prevIcon from '../../images/icon/prev.png';

import './style.scss';

const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, 
        responsive: [
          {
          breakpoint: 992,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
              arrows: true
          }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true
          }
          },
          {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true
          }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
          ]
};

const Video = (props) => {
    useEffect(() => {
        props.loadVideoAction();
    }, []);


//alert(props);
console.log(props.video);
        return (
                <Grid className="video-gallery-wrapper">
                    <Grid container  className="container">
                        <Grid item xs={12}>
                             <h1>Captured Live</h1>
                            <Grid className="ourExpert video-gallery-slider">
                            {props.video.video_list !== undefined ?
                             <Fragment>
                             <Slider {...settings}>
                             {props.video.video_list.map(item => {
                                                return (
                                                        <Grid key={item.id} className="expertTeam slide-box">
                                                            <Grid className="top-video">
                                                                <Grid className="play-icon"></Grid>
                                                                <ReactPlayer width="100%" height="276px"  url={item.video_link}  />
                                                            </Grid>
                                                            
                                                            <Grid className="content">
                                                                <Grid className="date">{item.created_at}</Grid>
                                                                <Grid>{item.title}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                )
                                            })}
                                        </Slider>
                                    </Fragment> : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

        )
    }


const mapStateToProps = state => {
    return {
        video: state.video
    }
};

export default connect(mapStateToProps, {loadVideoAction})(Video);