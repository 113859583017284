import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import {loadEventAction} from "../../store/actions/action";
import ReactHtmlParser from 'react-html-parser';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import SectionTitle from "../SectionTitle";

import nextIcon from '../../images/icon/next.png';
import prevIcon from '../../images/icon/prev.png';

import './style.scss';

const settings = {
    dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, 
        responsive: [
          {
          breakpoint: 992,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              arrows: true
          }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true
          }
          },
          {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true
          }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
          ]
};

const Event = (props) => {
    useEffect(() => {
        props.loadEventAction();
    }, []);
     
//alert(props);
var upcoming_events = [];
upcoming_events = props.event.upcoming_event_list;


        return (

            <Grid>
                        <Grid className="ourExpertWrapper news-and-events-wrapper">
                        <Grid container className="container">
                            <Grid item xs={12}>
                                <Grid className="safety-sciences">
                                    <Grid className="title wow fadeInLeft">{ReactHtmlParser(props.event.work_sub_title ? props.event.work_sub_title : '')}</Grid>
                                    <Grid className="safety-right-content wow fadeInRight">{ReactHtmlParser(props.event.work_des ? props.event.work_des : '')}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                                <Grid className="ourExpert  news-and-events-slider">
                                {props.event.event_list !== undefined ?
                                    <Fragment>
                                    <Slider {...settings}>
                                    {props.event.event_list.map(item => {
                                                    return (
                                                            <Grid key={item.id} className="expertTeam slide-box">
                                                                <Grid className="top-img"><img src={ReactHtmlParser(item.image ? item.image : '')} alt=""/></Grid>
                                                                <Grid className="content">
                                                                    <h4>{ReactHtmlParser(item.event_date ? item.event_date : '')}</h4>
                                                                    <h4 className="black-heading">{ReactHtmlParser(item.name ? item.name : '')}</h4>
                                                                    <p>{ReactHtmlParser(item.description ? item.description : '')}</p>
                                                                    <Link to={`/news/${item.id}`} target='_blank' className='read-more'>Read More</Link>
                                                                </Grid>
                                                            </Grid>
                                                    )
                                                })}
                                            </Slider>
                                        </Fragment> : ''}
                                </Grid>

               

               </Grid>

               { props.event.frontend_upcoming_event == '1' ? 
               <Fragment>
               { upcoming_events?.length > 0 ?
               <Grid className="upcoming-events-wrapper">
                    <Grid className="container">
                        <h1 className="wow fadeInUp">{ReactHtmlParser(props.event.work_title ? props.event.work_title : '')}</h1>
                        <Grid className="upcoming-events-list">
                                        <Grid className="ourExpert news-and-events-slider">
                                        { upcoming_events?.length > 0 ?
                                          <Fragment>
                                          {props.event.upcoming_event_list.map(item => {
                                                          return (
                                                          <Grid key={item.id} className="expertTeam slide-box">
                                      
                                                          <Grid className="upcoming-event wow fadeInRight" data-wow-delay="0.2s">
                                                              <Grid className="row">
                                                                  <Grid className="col-md-4 col-sm-4 com-xs-12">
                                                                      <Grid className="upcoming-event-left">
                                                                          <Grid className="upcoming-event-img"><img src={ReactHtmlParser(item.image ? item.image : '')} alt=""/></Grid>
                                                                          <Grid className="upcoming-event-date">{item.event_date}</Grid>
                                                                      </Grid>
                                                                  </Grid>
                                                                  <Grid className="col-md-8 col-sm-8 com-xs-12">
                                                                      <Grid className="upcoming-event-right">
                                                                          <h4>{ReactHtmlParser(item.name ? item.name : '')}</h4>
                                                                          <Grid className="upcoming-event-title">{ReactHtmlParser(item.description ? item.description : '')}</Grid>
                                                                          <Grid className="upcoming-event-description">{ReactHtmlParser(item.event_description.substr(' ',136))}</Grid>
                                                                          <Grid className="orange-btn"><a href={item.link} target="_blank">Register Your Entry</a></Grid>
                                                                      </Grid>
                                                                  </Grid>
                                                              </Grid>
                                                          </Grid>
                                                      </Grid>
                                                  )
                                              })}
                                       </Fragment> : <h2>There are no upcoming events.</h2> }
                                   </Grid>
                        </Grid>
                    </Grid>
               </Grid> : ' '}
               </Fragment> : ''}


    </Grid>

        )
    }


const mapStateToProps = state => {
    return {
        event: state.event
    }
};

export default connect(mapStateToProps, {loadEventAction})(Event);