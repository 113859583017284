import React from 'react';
import {Grid} from "@material-ui/core";
import { HashLink } from 'react-router-hash-link';
import './style.scss';

const ServiceStructure = ({className = '', features}) => {
    return (
            <Grid className={`featuredWrapper ${className}`}>
                <Grid className="services-box-wrapper" >
                
                    {features !== undefined ? features.map(item => {
                        return (
                            // <Grid key={item.id} >
                            <Grid key={item.id} className={`services-box ${features.length - 1 === item.id ? 'active' : ''}`}>
                            <Grid className="icon">
                                <img src={item.icon_image} alt=""/>
                            </Grid>
                            <Grid className="content">
                                <h4>{item.title.substr(' ',25)}</h4>
                                <p>{item.description.substr(' ',54)}</p>
                                <HashLink to={"/services#" + item.id} className="read-more" >Read More</HashLink>
                            </Grid>
                            </Grid>
                            // </Grid>
                        )
                    }) : ''}
                </Grid>
            </Grid>
    )
};

export default ServiceStructure;