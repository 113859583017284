import React from 'react';
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import Hidden from "@material-ui/core/Hidden";

// images
import Quotation from '../../images/quotation-marks.png';
import thumb from '../../images/tstthumb.jpg';
import next from '../../images/icon/next.png';
import prev from '../../images/icon/prev.png';

import './style.scss';


function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <img
            src={next}
            className={className}
            style={{...style, display: "inline-block"}}
            onClick={onClick}
            alt="next"
        />
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <img
            src={prev}
            className={className}
            style={{...style, display: "inline-block"}}
            onClick={onClick}
            alt="prev"
        />
    );
}

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
};

const TestimonialCarousel = (props) => {
    return (
        <Grid className="testimonialCarousel testimonials-slider">
            <Grid container className="container">
                <Grid item xs={12} md={12}>
                    {props.testimonialLists !== undefined ? <Slider {...settings}>
                        {props.testimonialLists.map(item => {
                            return (

                
                    <Grid className="slide-box">
                        <Grid container className="container">
                           <Grid item xs={12} md={7} sm={7}>
                              <Grid key={item.id} className="tstItem content" >
                                  <h2>2 Fusce vel neque nec nisl sollicitudin consectetur.</h2>
                                  <p>{item.messages}</p>
                                  <Grid className="author-name">{item.name}
                                      <small>{item.profession}</small>
                                  </Grid>
                              </Grid>
                            </Grid>
                            <Grid className="col-md-5 col-sm-5 com-xs-12">
                              <Grid className="top-img"><img src={item.image ? item.image : thumb} alt="user thumb"/></Grid>
                            </Grid>
                        </Grid>
                    </Grid>






                                // <Grid key={item.id} className="tstItem">
                                //     <p><img src={Quotation} alt=""/>{item.messages}</p>
                                //     <Grid className="tstAuthor">
                                //         <Grid className="avatar">
                                //             <img src={item.image ? item.image : thumb} alt="user thumb"/>
                                //         </Grid>
                                //         <h4>{item.name}</h4>
                                //         <strong>{item.profession}</strong>
                                //     </Grid>
                                // </Grid>

                            )
                        })}
                    </Slider> : ''}
                </Grid>
            </Grid>
        </Grid>
    )
};

export default TestimonialCarousel;