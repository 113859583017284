import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid} from "@material-ui/core";
import ServiceStructure from "../../components/ServiceStructure";
import {loadServiceAction} from "../../store/actions/action";
// images
import bgShape2 from '../../images/bg-shape2.png';
import who_we_are from '../../images/home/who-we-are-img.jpg';

import './style.scss';



const Services = (props) => {
    useEffect(() => {
        props.loadServiceAction();
    }, []);
    
        return (
            <Fragment>
                <ServiceStructure features={props.service.service_list} className="allFeaturs"/>
            </Fragment>
               )
    };

    const mapStateToProps = state => {
        return {
            service: state.service
        }
    };
    
export default connect(mapStateToProps, {loadServiceAction})(Services);
