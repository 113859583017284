import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import {loadAdvantageAction} from "../../store/actions/action";

import {withTranslation} from "react-i18next";

import SectionTitle from "../SectionTitle";

import nextIcon from '../../images/icon/next.png';
import prevIcon from '../../images/icon/prev.png';

import './style.scss';

const settings = {
    dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, 
        responsive: [
          {
          breakpoint: 992,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              arrows: true
          }
          },
          {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true
          }
          },
          {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true
          }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
          ]
};

const Advantage = (props) => {
    useEffect(() => {
        props.loadAdvantageAction();
    }, []);
//alert(props);
//console.log(props.advantage);
        return (

                        <Grid item xs={12}>
                            <Grid className="ourExpert services-slider">
                            {props.advantage.advantage_list !== undefined ?
                             <Fragment>
                             <Slider {...settings}>
                             {props.advantage.advantage_list.map(item => {
                                                return (
                                                        <Grid key={item.id} className="expertTeam slide-box">
                                                            <Grid className="top-img spin circle"><img src={item.image} alt=""/></Grid>
                                                            <Grid className="content">
                                                                <h4 className="advantage-center-title">{item.title}</h4>
                                                                {/* <p>{item.description}</p> */}
                                                            {/* <a href="/home" className="read-more">Read More</a> */}
                                                            </Grid>
                                                        </Grid>
                                                )
                                            })}
                                        </Slider>
                                    </Fragment> : ''}
                            </Grid>
                        </Grid>


                //     <Grid item xs={12}>
                //         <Grid item xs={12} md={10}>
                //           {props.advantage.advantage_list !== undefined ? <Slider {...settings}>
                //             {props.advantage.advantage_list.map(item => {
                //             return (
                //                     <Grid key={item.id} className="expertTeam services-box">
                //                         <Grid className="top-img"><img src={item.image} alt=""/></Grid>
                //                         <Grid className="content">
                //                             <h4>{item.title}</h4>
                //                             <p>{item.description}</p>
                //                         <a href="/home" className="read-more">Read More</a>
                //                         </Grid>
                //                     </Grid>

                //             )
                //         })}
                //     </Slider> : ''}
                // </Grid>
                //     </Grid>
               
        )
    }


const mapStateToProps = state => {
    return {
        advantage: state.advantage
    }
};

export default connect(mapStateToProps, {loadAdvantageAction})(Advantage);