import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import ContactIntro from "../../components/ContactIntro";
import ReactHtmlParser from 'react-html-parser';
import Job from "../../components/Job";
import Footer from "../../components/Footer";
import BreadCrumb from "../../components/BreadCrumb";
import ContactUs from "../../components/ContactUs";
import { withTranslation } from 'react-i18next';
import {loadCareerAction} from "../../store/actions/action";
import './style.scss';




const CareerPage = (props) => {

    useEffect(() => {
        props.loadCareerAction();
    }, []);
    
   console.log(props.career);

    return (
        <Fragment>
           
             <Grid className="servicesIntroWrapper">
                <Grid className="inner-page-banner">
                    <Grid className="banner-img"><img src={props.career.career_banner_image ? props.career.career_banner_image : ''} alt=""/></Grid>
                    <Grid className="content">
                        <Grid className="inner-content"> 
                            {ReactHtmlParser(props.career.career_banner_title ? props.career.career_banner_title : '')}
                        </Grid>
                        <ul className="breadcrumb wow fadeInLeft" data-wow-delay="0.4s">
                            <li><a href="/home">Home</a></li>
                            <li className="active">Career</li>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
           
           <Job jobs ={props.career} />
                <ContactUs/>
            <Footer/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        career: state.career
    }
};

export default connect(mapStateToProps, {loadCareerAction})(CareerPage);
