import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from 'react-html-parser';

import Advantage from "../../components/Advantage";

import abdtdotShape from '../../images/abdtdotshape.png';
import introLeft from '../../images/intro-left-image.jpg';

import './style.scss';

class ContactIntro extends React.Component {
    render(){


     const {banner_title_contact,contcat_safety_science,us_address,india_address,address_blue_section,map_location,contact_banner } = this.props.about;

  
     console.log(this.props)


    return (
        <Grid className="aboutIntroWrapper">

            <Grid className="inner-page-banner">
                <Grid className="banner-img"><img src={contact_banner ? contact_banner : ''} alt=""/></Grid>
                <Grid className="content">
                    <Grid className="inner-content">     
                        <h2 class="wow fadeInLeft"data-wow-delay="0.2s">{ReactHtmlParser(banner_title_contact ? banner_title_contact : '')}</h2>
                    </Grid>

                   <ul className="breadcrumb wow fadeInLeft" data-wow-delay="0.4s">
                        <li><a href="/home">Home</a></li>
                        <li className="active">Contact us</li>
                    </ul>
                </Grid>
             </Grid>

    {/* <Grid className="contact-us-wrapper">
        <Grid className="container">
            <Grid className="row">
                <Grid  className="col-12 col-sm-12 col-md-4">
                    <Grid className="contact-us">
                        <h1>{ReactHtmlParser(contcat_safety_science ? contcat_safety_science : 'contcat_safety_science')}</h1>
                        <Grid className="contact-info-top">
                             {ReactHtmlParser(us_address ? us_address : 'us_address')}
                        </Grid>
                        <Grid className="contact-box">
                             {ReactHtmlParser(india_address ? india_address : 'india_address')}
                        </Grid>
                        <Grid className="contact-media-wrapper">
                            <h4>Social</h4>
                            <ul>
                                <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="col-12 col-sm-12 col-md-8">
                    <Grid className="contact-us-map">
                        {ReactHtmlParser(address_blue_section ? address_blue_section : 'address_blue_section')}
                        <Grid className="map">
                        {ReactHtmlParser(map_location ? map_location : 'map_location')}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid> */}
              


          
        </Grid>
    )
    }
};

export default withTranslation('common')(ContactIntro); 