import React, {Component} from 'react';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import FontAwesome from "../UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";
import {withTranslation} from "react-i18next";
import Joi from "joi-browser";
import {toast} from "react-toastify";
import {postRequest} from "../../utils/request";
import ReactHtmlParser from 'react-html-parser';

//import footerBgShape from '../../images/footer-bg.svg';
import logo from '../../images/logo.png';

import './style.scss';

const sociallinks = [
    // {
    //     id: 1,
    //     name: 'facebook',
    //     link: '/home',
    // },
    {
        id: 2,
        name: 'linkedin',
        link: 'https://www.linkedin.com/mwlite/company/elite-safety-sciences/about',
    },
    // {
    //     id: 3,
    //     name: 'youtube',
    //     link: '/home',
    // },
    {
        id: 4,
        name: 'twitter',
        link: 'https://www.twitter.com/life_ess',
    },
];

class Footer extends Component {

    state = {
        email: '',
        error: {},
    };

    schema = {
        email: Joi.string()
            .required()
            .email()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'string.email':
                            err.message = 'Email Must be Email Format';
                            break;
                        case 'any.required':
                            err.message = 'Email is Requared';
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
    };

    changeHandler = event => {
        const error = {...this.state.error};
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error,
        });
    };


    validationProperty = event => {
        const Obj = {[event.target.name]: event.target.value};
        const schema = {[event.target.name]: this.schema[event.target.name]};
        const {error} = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null;
    };

    validate = () => {
        const options = {abortEarly: false};
        const form = {
            email: this.state.email,
        };
        const {error} = Joi.validate(form, this.schema, options);
        if (!error) return null;

        const errors = {};
        for (const item of error.details) errors[item.path[0]] = item.message;
        return errors;
    };

    submitHandler = event => {
        event.preventDefault();
        const error = this.validate();
        this.setState({
            error: error || {},
        });
        if (!error) {

            postRequest('subscription-process', {
                email: this.state.email
            })
                .then(res => {
                    if (res.data.success !== false) {
                        toast.success(res.data.message);
                        this.setState({
                            email: '',
                        })
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error('Not Send!');
                });
        }
    };
   
    render() {
        const {header} = this.props;
        if (window.location.pathname == "/knowledge-center") {
        return (
                <Grid className="footerWrapper1">
                    <Grid className="disclaimer-wrapper">
                        <Grid className="disclaimer-Bottom">
                            <Grid className="container">
                                <Grid container>
                                    <Grid item xs={12}>
                                        {console.log(this.props)}
                                        {ReactHtmlParser(header.disclaimer ? header.disclaimer : '')}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="copyright-wrapper">
                        <Grid className="footerBottom">
                            <Grid className="container">
                                <Grid container>
                                    <Grid item xs={12}>
                                        {console.log(this.props)}
                                        <p>{this.props.copyright_text}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
               )
        }else{
            return (
                <Grid className="footerWrapper1">
                    <Grid className="copyright-wrapper">
                        <Grid className="footerBottom">
                            <Grid className="container">
                                <Grid container>
                                    <Grid item xs={12}>
                                        {console.log(this.props)}
                                        <p>{this.props.copyright_text}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
               )
        }
       
    };
}

const mapStateToProps = state => {
    return {
        header: state.header,
        copyright_text: state.header.copyright_text
    }
};

export default connect(mapStateToProps)(withTranslation('common')(Footer));