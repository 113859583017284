import React, {Fragment, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import WorkInquiry from "../../components/WorkInquiry";
import Footer from "../../components/Footer";
import PortfolioMasonary from "../../components/PortfolioMasonary";
import Video from "../../components/Video";
import BreadCrumb from "../../components/BreadCrumb";
import {loadGalleryAction} from "../../store/actions/action";

const Gallery = (props) => {

    useEffect(() => {
        props.loadGalleryAction();
    }, []);


    return (
        <Fragment>
            <Grid className="galleryIntroWrapper">
                <Grid className="inner-page-banner">
                    <Grid className="banner-img"><img src={props.gallery.gallery_banner_image ? props.gallery.gallery_banner_image : ''} alt=""/></Grid>
                    <Grid className="content">
                        <Grid className="inner-content"> 
                            {ReactHtmlParser(props.gallery.gallery_banner_title ? props.gallery.gallery_banner_title : '')}
                        </Grid>
                        <ul className="breadcrumb wow fadeInLeft" data-wow-delay="0.4s">
                            <li><a href="/home">Home</a></li>
                            <li className="active">Gallery</li>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
            {/* {ReactHtmlParser(props.gallery.moments_title ? props.gallery.moments_title : 'gallery_banner_title')}
            {ReactHtmlParser(props.gallery.captured_live_title ? props.gallery.captured_live_title : 'gallery_banner_title')} */}


            {/* <BreadCrumb
                pagename="Creative Protfolio"
                title={props.gallery.gallery_banner_title}
            /> */}
            <PortfolioMasonary image_list={props.gallery} />
            { props.gallery.frontend_video_setting == '1' ? 
            <Video/>
            : ''}
            {/* <WorkInquiry workInquiry={props.gallery} className="hasMb"/> */}
            <Footer/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        gallery: state.gallery
    }
};

export default connect(mapStateToProps, {loadGalleryAction})(Gallery);