import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import {loadEventAction} from "../../store/actions/action";
import ReactHtmlParser from 'react-html-parser';
import {withTranslation} from "react-i18next";

import SectionTitle from "../SectionTitle";

import nextIcon from '../../images/icon/next.png';
import prevIcon from '../../images/icon/prev.png';

import './style.scss';


const EventsTop = (props) => {
    useEffect(() => {
        props.loadEventAction();
    }, []);
//alert(props);
console.log(props);


        return (
            <Grid className="eventIntroWrapper">
            <Grid class="inner-page-banner">
                <Grid class="banner-img"><img src={props.event.work_image ? props.event.work_image : ''} alt=""/></Grid>
                <Grid class="content">
                    <Grid className="inner-content"> 
                        {ReactHtmlParser(props.event.work_header_title ? props.event.work_header_title : '')}
                    </Grid>
                    <ul class="breadcrumb wow fadeInLeft" data-wow-delay="0.4s">
                        <li><a href="/home">Home</a></li>
                        <li class="active">Knowledge Center</li>
                    </ul>
                </Grid>
            </Grid>
         

            </Grid>
        )
    }


const mapStateToProps = state => {
    return {
        event: state.event
    }
};

export default connect(mapStateToProps, {loadEventAction})(EventsTop);