import React, {Fragment, useState, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from 'react-html-parser';
import Button from "@material-ui/core/Button";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './style.scss';

function searchFilter(row, category) {
    return row.category_name.toLowerCase().includes(category.toLowerCase()) || !category;
}

 

const PortfolioMasonary = ({image_list}) => {
    const [category, setCategory] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    function onOpenHandler(id) {
        setOpen(true);
        setPhotoIndex(id - 1);
    }

    const gCatList = image_list.image_list !== undefined ? [...new Set(image_list.image_list.map(a => a.category_name))] : [];
    const glry = image_list.glrycategories !== undefined ? [...new Set(image_list.glrycategories.map(a => a.name))] : [];
 
console.log(glry);

    return (
        <Fragment>
            <Grid className="gallery-list-wrapper">
                <Grid container spacing={4} className="container">
                    <Grid item xs={12}>
                        <Grid className="portfolioFilter">
                        <h1 class="wow fadeInUp">Some Assorted Moments</h1>
                            <Button
                                className={`cBtn cBtnRadius cBtnOutline cBtnUppercase ${category === '' ? 'active' : ''}`}
                                onClick={() => setCategory('',)}>All Images</Button>
                            {glry.map((item, i) => {
                                return (
                                    <Button key={i}
                                            className={`cBtn cBtnRadius cBtnOutline cBtnUppercase ${category === item ? 'active' : ''}`}
                                            onClick={() => setCategory(item)}>{item}</Button>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="portfolioGrid container" container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {image_list.image_list !== undefined ? image_list.image_list.filter(row => searchFilter(row, category)).map((item, i) => {
                            return (
                                <Grid key={item.id} item xs={12} sm={6} md={3} onClick={() => onOpenHandler(item.id)}>
                                    <img src={item.image} alt="portfolio"/>
                                    <Grid class="gallery-bottom-content">
                                        <Grid class="gallery-date">{item.name}</Grid>
                                        <Grid class="gallery-description">{item.description}</Grid>
                                    </Grid>
                                </Grid>
                            )
                        }) : ''}
                    </Grid>
                </Grid>
                {isOpen && (
                    <Lightbox
                        mainSrc={image_list.image_list[photoIndex].image}
                        nextSrc={image_list.image_list[(photoIndex + 1) % image_list.image_list.length].image}
                        prevSrc={image_list.image_list[(photoIndex + image_list.image_list.length - 1) % image_list.image_list.length].image}
                        onCloseRequest={() => setOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + image_list.image_list.length - 1) % image_list.image_list.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % image_list.image_list.length)}
                    />
                )} 
            </Grid>
            </Grid>
        </Fragment>
    )

};

export default PortfolioMasonary;