import React, {Fragment, useEffect} from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import ReactHtmlParser from 'react-html-parser';
import 'bootstrap/dist/css/bootstrap.css';
import Advantage from "../../components/Advantage";
import Advisor from "../../components/Advisor";
import { HashLink } from 'react-router-hash-link';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import centerlogo from '../../images/center-logo-icon.png';
import './style.scss';

class AboutIntro extends React.Component {
    render(){


    const {
        about_banner_image,about_banner_title,about_title,about_sub_title,about_description,about_sub_title2,about_description2,blue_section,gray_section,about_section_title2,about_section_des2,about_section_title3,about_section_des3,about_section_des4, home_section_title3,home_section_des3,home_section_des3_blue_tab,home_section_image_des5,home_fifth_section_image, home_section_title5, home_section_title5_blue_tab,about_section_title1,about_section_des1, home_section_sub_title5, home_section_des5, home_section_title6, home_section_des6,home_section_title2, second_section_sub_title, home_section_des2, about_left_image,team_list,frontend_advisor_setting
     } = this.props.about;

  
    // console.log(this.props);


    return (
        <Grid className="aboutIntroWrapper">

            <Grid className="inner-page-banner">
                <Grid className="banner-img"><img src={about_banner_image ? about_banner_image : ''} alt=""/></Grid>
                <Grid className="content">
                    <Grid className="inner-content"> 
                        {ReactHtmlParser(about_banner_title ? about_banner_title : '')}
                    </Grid>    
                    <ul className="breadcrumb wow fadeInLeft" data-wow-delay="0.4s">
                        <li><a href="/home">Home</a></li>
                        <li className="active">About us</li>
                    </ul>
                </Grid>
            </Grid>

            <Grid className="who-we-are-wrapper about-rev-wrapper"> 
                <Grid container alignItems="center" className="container">
                    <Grid className="justify-content-center row">
                        <Grid item xs={12} sm={6} md={6} >
                            <Grid className="who-we-are-left">
                                {/* {ReactHtmlParser(blue_section ? blue_section : '')} */}
                                <Grid className="who-we-are-img wow fadeInUp"><img src={about_left_image ? about_left_image : ''} alt=""/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Grid className="who-we-are-right-content img-sec">
                                <Grid className="top-content wow fadeInUp">
                                    <h1>{ReactHtmlParser(about_section_title1 ? about_section_title1 : '')}</h1>
                                        {ReactHtmlParser(about_section_des1 ? about_section_des1 : '')}
                                </Grid>  

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container alignItems="center" className="project-in-mind-wrapper">
                <Grid className="container">
                        <Grid item xs={12} sm={12} md={12}  >
                            <Grid className="project-in-mind  about-counter  wow fadeInUp">
                                <Grid className="wow fadeInLeft" data-wow-delay="0.4s">
                                    {/* <h4>{ReactHtmlParser(have_a_project_title ? have_a_project_title : 'have_a_project_title')}</h4> */}
                                    {ReactHtmlParser(gray_section ? gray_section : '')}
                                </Grid> 
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>

            <section className="our-mission-wrapper about-mission-wrapper">
                <Grid className="container">
                   {/* <Grid className="row justify-content-md-center">
                        <Grid className="col-xs-12 col-sm-12 col-sm-offset-0 col-md-10 col-md-offset-1">
                            <h4 className="wow fadeInUp"  data-wow-delay="0.2s">{ReactHtmlParser(about_section_des2 ? about_section_des2 : '')}</h4>
                        </Grid>
                    </Grid> */}
                    <h2 className="wow fadeInUp"  data-wow-delay="0.2s">{ReactHtmlParser(about_section_title2 ? about_section_title2 : '')}</h2>
                    <Grid className="customer-wrapper">
                        <Grid className="customer-box">
                            {/* {ReactHtmlParser(about_section_des3 ? about_section_des3 : '')} */}
                            <Grid className="customer-middle circular-img">
                                <Grid className="content wow bounceIn"  data-wow-delay="0.4s"><img src={centerlogo} alt=""/></Grid>
                            </Grid>
                            {/* {ReactHtmlParser(about_section_des4 ? about_section_des4 : '')} */}
                        </Grid>
                    </Grid>
                    <h2 className="wow fadeInUp about-title-small"  data-wow-delay="0.2s">{ReactHtmlParser(about_section_title3 ? about_section_title3 : '')}</h2>
                </Grid>
            </section>


            <Grid className="team-wrapper pddsec" id="team-wrapper">
                <Grid className="container">
                <Grid className="row">
                <Grid className="col-lg-4 col-md-4 col-sm-4 com-xs-12  hidden-xs-12 "></Grid>
                <Grid className="col-lg-8 col-md-8  col-sm-12 com-xs-12">
            <Grid class=" wow fadeInUp advisor-class-box">
            <h1 className='advisor-class'>{ReactHtmlParser(home_section_title5_blue_tab ? home_section_title5_blue_tab : '')}</h1>
                       
                  </Grid>
            </Grid>
            </Grid> 
                              
                         <Grid className="ourExpert management-team-wrap">
                               {team_list !== undefined ?
                                    <Fragment>
                            {team_list.map(item => {
                                return (
                                        <Grid key={item.id} className="expertTeam management-team">
                                            
                                        <Grid className="wow fadeInRight" data-wow-delay="0.2s">
                                            <Grid className="row">
                                                <Grid className="col-md-4 col-sm-4 com-xs-12">
                                                    <Grid className="management-team-left">
                                                        <Grid className="management-team-img"><img src={item.image} alt=""/></Grid>
                                                       </Grid>
                                                </Grid>
                                                <Grid className="col-md-8 col-sm-8 com-xs-12">
                                                    <Grid className="management-team-right">
                                                        <h4>{ReactHtmlParser(item.name ? item.name : '')}</h4>
                                                        <Grid>{ReactHtmlParser(item.bio ? item.bio : '')}</Grid> 
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })} 
                             </Fragment> : "There are no team."}
                             </Grid>

                        {/* <Grid item xs={12} sm={6} md={6}>
                            <Grid className="our-executive-left">
                                <Grid className="our-executive wow fadeInUp">{ReactHtmlParser(home_section_title5_blue_tab ? home_section_title5_blue_tab : 'home_section_title5_blue_tab')}</Grid>
                                <Grid className="our-executive-img wow fadeInUp" data-wow-delay="0.2s"><img src={home_fifth_section_image ? home_fifth_section_image : 'home_fifth_section_image'} alt=""/></Grid>
                                <Grid className="content wow fadeInUp" data-wow-delay="0.4s">{ReactHtmlParser(home_section_image_des5 ? home_section_image_des5 : 'home_section_image_des5')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                            <Grid className="team">
                                <h1 className="wow fadeInUp">{ReactHtmlParser(home_section_title5 ? home_section_title5 : 'home_section_title5')}</h1>
                                <h4 className="wow fadeInUp" data-wow-delay="0.2s">{ReactHtmlParser(home_section_sub_title5 ? home_section_sub_title5 : 'home_section_sub_title5')}</h4>
                                <ul className="custom-list wow fadeInUp" data-wow-delay="0.4s">
                                {ReactHtmlParser(home_section_des5 ? home_section_des5 : 'home_section_des5')}
                                </ul>
                            </Grid>
                        </Grid> */}
                </Grid>

                { frontend_advisor_setting == '1' ? 
                   <Advisor/>
                : ''}
              
            </Grid>

            <Grid className="team-wrapper team-wrapper-box">
                <Grid  className="container">
                <Grid className="row">
                <Grid className="col-lg-4 col-md-4 col-sm-4 com-xs-12 hidden-xs "></Grid>
                <Grid className="col-lg-8 col-md-8  col-sm-12 com-xs-12">
            <Grid class="advisor-class-box">
                <h1 className="wow fadeInUp advisor-class">{ReactHtmlParser(home_section_title5 ? home_section_title5 : '')}</h1>
                 </Grid>
            </Grid>
            </Grid>
                <Grid className="row">
               
                  <Grid className="col-lg-4 col-md-4 col-sm-4 com-xs-12 hidden-xs">
                            <Grid className="our-executive-left">
                                {/* <Grid className="our-executive wow fadeInUp">{ReactHtmlParser(home_section_title5_blue_tab ? home_section_title5_blue_tab : '')}</Grid> */}
                                <Grid className="our-executive-img wow fadeInUp" data-wow-delay="0.2s"> <HashLink to={"/company#" + 'team-wrapper'}><img src={home_fifth_section_image ? home_fifth_section_image : ''} alt=""/></HashLink></Grid>
                                {/* <Grid className="content wow fadeInUp" data-wow-delay="0.4s">{ReactHtmlParser(home_section_image_des5 ? home_section_image_des5 : '')}</Grid> */}
                            </Grid>
                        </Grid> 
                        <Grid className="col-md-8 col-sm-8 com-xs-12">
                            <Grid className="team">
                                
                                {/* <h4 className="wow fadeInUp" data-wow-delay="0.2s">{ReactHtmlParser(home_section_sub_title5 ? home_section_sub_title5 : '')}</h4> */}
                                <ul className="custom-list wow fadeInUp" data-wow-delay="0.4s">
                                {ReactHtmlParser(home_section_des5 ? home_section_des5 : '')}
                                </ul>
                            </Grid>
                        </Grid>
                        </Grid>
                </Grid>
            </Grid>
          


            {/* <section className="governing-principle-wrapper">
                <Grid className="container">
                     <h1 className="wow fadeInUp">{ReactHtmlParser(about_title ? about_title : '')}</h1> 
                    <Grid className="row justify-content-md-center ">
                        <Grid item xs={12} sm={12} md={10} className=" col-sm-offset-0  col-md-offset-1">
                            <Grid className="panel-group  wow fadeInUp" id="accordion" role="tablist" aria-multiselectable="true" data-wow-delay="0.2s">
                                <Grid className="panel panel-default">
                                    <Accordion>
                                        <AccordionSummary  expandIcon={<ExpandMore />}>
                                        <h4>{ReactHtmlParser(about_sub_title ? about_sub_title : '')}</h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {ReactHtmlParser(about_description ? about_description : '')}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid className="panel panel-default">
                                    <Accordion>
                                        <AccordionSummary  expandIcon={<ExpandMore />}>
                                        <h4>{ReactHtmlParser(about_sub_title2 ? about_sub_title2 : '')}</h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {ReactHtmlParser(about_description2 ? about_description2 : '')}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </section> */}

            {/* <Grid className="ourExpertWrapper safety-sciences-wrapper">
                  <Grid container className="container">
                   <Grid item xs={12}>
                      <Grid className="safety-sciences">
                             <Grid className="title wow fadeInLeft">{ReactHtmlParser(home_section_title6 ? home_section_title6 : '')}</Grid>
                             <Grid className="safety-right-content wow fadeInRight">{ReactHtmlParser(home_section_des6 ? home_section_des6 : '')}
                        </Grid>
                      </Grid>
                    </Grid>
                    </Grid>
                <Advantage/>
            </Grid> */}







            <Grid
                container
                spacing={4}
                alignItems="center"
                className="container"
            >
                {/* <Grid item xs={12} md={5}>
                    <Grid className="thumb">
                        { <img src={about_feature_left_image ? about_feature_left_image : introLeft} alt="leftImage"/> }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid className="introList">
                        <span>01</span>
                        <h4>{about_feature_title1 ? about_feature_title1 : 'Our History'}</h4>
                        <p>{about_feature_des1 ? about_feature_des1 : 'The making of WigWag. Exposing the secret behind the complex story behind the brand we all know and love.'}</p>
                    </Grid>
                    <Grid className="introList">
                        <span>02</span>
                        <h4>{about_feature_title2 ? about_feature_title2 : 'Our Principles'}</h4>
                        <p>{about_feature_des2 ? about_feature_des2 : 'Ready to solve problems and eager to apply new ideas. This is the brave territory where we come together, and it’s hard to get there if you’re not having fun'}</p>
                    </Grid>
                    <Grid className="introList">
                        <span>03</span>
                        <h4>{about_feature_title3 ? about_feature_title3 : 'Business success'}</h4>
                        <p>{about_feature_des3 ? about_feature_des3 : 'Ready to solve problems and eager to apply new ideas. This is the brave territory where we come together, and it’s hard to get there if you’re not having fun..'}</p>
                    </Grid>
                    <Grid className="introList">
                        <span>04</span>
                        <h4>{about_feature_title4 ? about_feature_title4 : 'Digital Solution'}</h4>
                        <p>{about_feature_des4 ? about_feature_des4 : 'Ready to solve problems and eager to apply new ideas. This is the brave territory where we come together, and it’s hard to get there if you’re not having fun..'}</p>
                    </Grid>
                </Grid> */}
            </Grid>
        </Grid>
    )
    }
};

export default withTranslation('common')(AboutIntro); 