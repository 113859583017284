import React, {Fragment, Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Footer from "../../components/Footer";
import NewsTop from "../../components/NewsTop";
import Event from "../../components/Event";
import ReactHtmlParser from 'react-html-parser';
import NewsLetter from "../../components/NewsLetter";
import Comments from "../../components/Comments";
import CommentForm from "../../components/CommentForm";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import {Link} from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import {loadSingleNews} from "../../store/actions/action";


import './style.scss';

class NewsPage extends Component {

    state = {
        id: ''
    };

    componentDidMount() {
        this.props.loadSingleNews(this.props.match.params.id);
        this.setState({
            id: this.props.match.params.id
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (state.id !== props.match.params.id) {
            state.id = props.match.params.id
            props.loadSingleNews(props.match.params.id);

        }
        return true;
    }
  
    render() {
        const {news} = this.props;
        //console.log(news);
        return (
            <Fragment>
                   <NewsTop/>
                        <Grid className="news-details-wrapper">
                                <Grid className="container">
                                    <h1 className="wow fadeInUp">{ReactHtmlParser(news.name ? news.name : '')}</h1>
                                    <Grid className="news-details">
                                        <Grid className="news-date wow fadeInUp" data-wow-delay="0.2s">{ReactHtmlParser(news.event_date ? news.event_date : '')}</Grid>
                                        <Grid className="news-detail-img-wrapper wow fadeInUp">
                                            <Grid className="news-detail-img"><img src={news.image} alt=""/></Grid>
                                            <Grid className="news-detail-title">{ReactHtmlParser(news.description ? news.description : '')}</Grid>
                                        </Grid>
                                        <Grid className="news-detail-content">
                                            <Grid className="news-detail-description wow fadeInUp">
                                            {ReactHtmlParser(news.event_description ? news.event_description : '')}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        <Event/>
                <Footer/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        news: state.news
    }
};

export default connect(mapStateToProps, {loadSingleNews})(withRouter(NewsPage));