import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import ModalVideo from 'react-modal-video';
import 'bootstrap/dist/css/bootstrap.min.css';

import Services from "../../components/Services";
import ServiceMobile from "../../components/ServiceMobile";
import Advantage from "../../components/Advantage";
import {withTranslation} from "react-i18next";

import ReactHtmlParser from 'react-html-parser';

import { HashLink } from 'react-router-hash-link';

// images
import bgShape1 from '../../images/bg-shape1.png';
import Shape2 from '../../images/shape1.png';
import who_we_are from '../../images/home/who-we-are-img.jpg';
import director_img from '../../images/home/director-img.jpg';
import '../../css/font-awesome.min.css';
import './style.scss';

class HeroArea extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false
        };
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({isOpen: true})
    }

    render() {

        const {have_a_project_title, have_a_project_description, home_section_title2, second_section_sub_title, second_section_blue_tab, home_section_des2, home_second_section_image, home_section_title3, home_section_des3, home_section_des3_blue_tab, home_section_title4, home_section_des4, home_fifth_section_image, home_section_image_des5, home_section_title5, home_section_title5_blue_tab, home_section_sub_title5, home_section_des5, home_section_title6, home_section_des6} = this.props.hero;
        return (
            <Grid className="heroAreaWapper">

            <Grid container alignItems="center" className="project-in-mind-wrapper">
                <Grid className="container">
                        <Grid item xs={12} sm={12} md={12}  >
                            <Grid className="project-in-mind  wow fadeInUp">
                                <Grid className="wow fadeInLeft" data-wow-delay="0.4s">
                                    
                                    {/* <h4>{ReactHtmlParser(have_a_project_title ? have_a_project_title : 'have_a_project_title')}</h4> */}
                                    {ReactHtmlParser(have_a_project_description ? have_a_project_description : '')}
                                </Grid> 
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>

            {/*<Grid className="who-we-are-wrapper"> 
                <Grid container alignItems="center" className="container"> 
                    <Grid className="justify-content-center row">
                        <Grid item xs={12} sm={5} md={5} className="align-self-start" >
                            <Grid className="who-we-are-left">
                                 {ReactHtmlParser(second_section_blue_tab ? second_section_blue_tab : '')} 
                                <Grid className="who-we-are-img wow fadeInUp"><img src={home_second_section_image ? home_second_section_image : ''} alt=""/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Grid className="who-we-are-right-content">
                                <Grid className="top-content wow fadeInUp">
                                    {/* <h1>{ReactHtmlParser(home_section_title2 ? home_section_title2 : '')}</h1> 
                                    <h4>{ReactHtmlParser(second_section_sub_title ? second_section_sub_title : '')}</h4>
                                        {ReactHtmlParser(home_section_des2 ? home_section_des2 : '')}
                                </Grid>  

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>*/}

            <Grid className="our-mission-wrapper">
                <Grid  className="container" >
                    <h1 className="wow fadeInUp">{ReactHtmlParser(home_section_title3 ? home_section_title3 : '')}</h1>
                        <Grid  item xs={12} sm={12} md={12} >
                            <h4 className="wow fadeInUp"  data-wow-delay="0.2s">{ReactHtmlParser(home_section_des3 ? home_section_des3 : '')}</h4>
                        </Grid>
                    <Grid className="customer-wrapper">
                    {ReactHtmlParser(home_section_des3_blue_tab ? home_section_des3_blue_tab : '')}
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid className="services-wrapper">
                <Grid className="container">
                    <Grid className="we-provide-section">
                        <h1 className="wow fadeInLeft">{ReactHtmlParser(home_section_title4 ? home_section_title4 : '')}</h1>
                        <p className="wow fadeInLeft"  data-wow-delay="0.2s">{ReactHtmlParser(home_section_des4 ? home_section_des4 : '')}</p>
                    </Grid>
                    <Services/>
                </Grid>
                <ServiceMobile/>
            </Grid> */}
            

            

            {/*<Grid className="team-wrapper">
                <Grid container className="container">
                         <Grid item xs={12} sm={6} md={6}>
                            <Grid className="our-executive-left">
                                 <Grid className="our-executive wow fadeInUp">{ReactHtmlParser(home_section_title5_blue_tab ? home_section_title5_blue_tab : '')}</Grid> 
                                <Grid className="our-executive-img wow fadeInUp" data-wow-delay="0.2s"> <HashLink to={"/company#" + 'team-wrapper'}><img src={home_fifth_section_image ? home_fifth_section_image : ''} alt=""/></HashLink></Grid>
                                 <Grid className="content wow fadeInUp" data-wow-delay="0.4s">{ReactHtmlParser(home_section_image_des5 ? home_section_image_des5 : '')}</Grid> 
                            </Grid>
                        </Grid> 
                        <Grid item xs={12} sm={6} md={6}>
                            <Grid className="team">
                                <h1 className="wow fadeInUp">{ReactHtmlParser(home_section_title5 ? home_section_title5 : '')}</h1>
                                {/* <h4 className="wow fadeInUp" data-wow-delay="0.2s">{ReactHtmlParser(home_section_sub_title5 ? home_section_sub_title5 : '')}</h4> 
                                <ul className="custom-list wow fadeInUp" data-wow-delay="0.4s">
                                {ReactHtmlParser(home_section_des5 ? home_section_des5 : '')}
                                </ul>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>*/}


            <Grid className="ourExpertWrapper safety-sciences-wrapper">
                  <Grid container className="container">
                   <Grid item xs={12}>
                    {/* <Grid item xs={12}>
                        <SectionTitle
                            subtitle={this.props.t('Home.OurExpert.SubTitle')}
                            title={this.props.t('Home.OurExpert.Title')}
                        />
                    </Grid> */}
                      <Grid className="safety-sciences">
                             <Grid className="title wow fadeInLeft">{ReactHtmlParser(home_section_title6 ? home_section_title6 : '')}</Grid>
                             <Grid className="safety-right-content wow fadeInRight">{ReactHtmlParser(home_section_des6 ? home_section_des6 : '')}
                        </Grid>
                      </Grid>
                    </Grid>
                    </Grid>
                {/* <Advantage/> */}
            </Grid>

                {/* <Grid container alignItems="center" className="container">
                    <Grid item xs={12} md={6}>
                        <Grid className="heroContent">
                            <strong>{home_banner_sub_title ? home_banner_sub_title : `${this.props.t('Home.HeroArea.SubTitle')}`}</strong>
                            <h2>{home_banner_title ? home_banner_title : `${this.props.t('Home.HeroArea.Title')}`}</h2>
                            <p>{home_banner_des ? home_banner_des : `${this.props.t('Home.HeroArea.Description')}`}</p>
                            <Link to="/about-us" className="cBtn">{this.props.t('Home.HeroArea.ButtonText')}</Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid className="hrRighThumb">
                            <img src={home_banner_image ? home_banner_image : Shape2} alt="home_banner_image"/>
                        </Grid>
                    </Grid>
                </Grid>
                <img className="bgShape1" src={bgShape1} alt="shape"/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='DGQwd1_dpuc' onClose={() => this.setState({isOpen: false})}/> */}

            </Grid>
        )
    }
}

export default withTranslation('common')(HeroArea);