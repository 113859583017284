import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import {loadAddressAction} from "../../store/actions/action";
import ReactHtmlParser from 'react-html-parser';
import {withTranslation} from "react-i18next";
import SectionTitle from "../SectionTitle";

import nextIcon from '../../images/icon/next.png';
import prevIcon from '../../images/icon/prev.png';

import './style.scss';


class Address extends React.Component {
    state = {
        mapId: null,
        active: false,
      };
    
   
    render(){
        const currentState = this.state.active;  
       
    const {address_list, contcat_safety_science, address_blue_section, map_location} = this.props.about;
    // this.setState(
    //     prevState => ({ mapId: address_list['0'].id })
    //   )
  
   // console.log(this.props.about.address_list['0'].map);


     return (
         
        <Grid className="contact-us-wrapper">
        <Grid className="ourExpert services-slider">
                                   {address_list !== undefined ?
                                    <Fragment>
                                 
                                   
                                                   
                                <Grid className="container">
                                    <Grid className="row">
                                        <Grid  className="col-12 col-sm-12 col-md-4">
                                            <Grid className="contact-us">
                                                <h1>{ReactHtmlParser(contcat_safety_science ? contcat_safety_science : '')}</h1>
                                                <Grid className="address-wrapper">
                                                    {address_list.map(item => {
                                                    return (
                                                            <Grid key={item.id} className="contact-info-top contact-box" onClick = { () =>{
                                                                this.setState(
                                                                prevState => ({ mapId: item.id }) 
                                                              ) 
                                                              this.setState({ active: !currentState });
                                                            } }  >{ReactHtmlParser(item.address ? item.address : '')}</Grid>
                                                           )
                                                            })}
                                                </Grid>
                                                                        <Grid className="contact-media-wrapper">
                                                                            <h4>Social</h4>
                                                                            <ul>
                                                                                <li><a href="https://www.linkedin.com/company/elite-safety-sciences/about/" target="_blank" ><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                                                <li><a href="https://www.twitter.com/life_ess" target="_blank" ><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                                                {/* <li><a href="#"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                                                                <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                                                                <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li> */}
                                                                            </ul>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Grid className="col-12 col-sm-12 col-md-8">
                                                                    <Grid className="contact-us-map">
                                                                        {ReactHtmlParser(address_blue_section ? address_blue_section : '')}
                                                                        <Grid className="map">
                                                                        {ReactHtmlParser(map_location ? map_location : '')}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid> */}
                                                               <Grid className="col-12 col-sm-12 col-md-8">
                                                                {address_list.map(item => { 
                                                                    return(
                                                                          item.id == this.state.mapId  && <Grid className="contact-us-map">
                                                                            <Grid className="map-address-box">
                                                                                {ReactHtmlParser(item.blue_tab ? item.blue_tab : '')}
                                                                            </Grid>
                                                                            <Grid className="map">
                                                                            {ReactHtmlParser(item.map ? item.map : '')}
                                                                            </Grid>
                                                                        </Grid>
                                                                         )
                                                                })}
                                                                          <Grid className="contact-us-map">
                                                                            <Grid className="map-address-box">
                                                                            { !this.state.mapId ? ReactHtmlParser(address_list['0'].blue_tab ? address_list['0'].blue_tab : '') : '' }
                                                                            </Grid>
                                                                            <Grid className="map">
                                                                            { !this.state.mapId ? ReactHtmlParser(address_list['0'].map ? address_list['0'].map : '') : '' }
                                                                            </Grid>
                                                                          </Grid>
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    
                                               
                                          
                                        </Fragment> : "There are no address."}
                                   </Grid>
        </Grid>
    )
    }
};

export default withTranslation('common')(Address); 
